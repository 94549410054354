@use "./variables" as *;

:root[data-theme="theme-light"] {
  --primary: map-get($colors, primary);
  --secondary: map-get($colors, secondary);
  --bg-primary: map-get($bg-colors, primary);
  --bg-secondary: map-get($bg-colors, secondary);
};

:root[data-theme="theme-dark"] {
  --primary: map-get($dark-colors, primary);
  --secondary: map-get($dark-colors, secondary);
  --bg-primary: map-get($bg-dark-colors, primary);
  --bg-secondary: map-get($bg-dark-colors, secondary);
};