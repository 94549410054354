* {
  font-family: "Poppins", sans-serif;
}

html {
  width: 100%;
  height: max-content;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: max-content;
}

#root {
  width: 100%;
  height: max-content;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

section {
  width: 100%;
  height: max-content;
}

.container-xxl {
  max-width: 1440px !important;
  background-color: transparent;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
}

.container-xl {
  max-width: 1120px !important;
  background-color: transparent;
  height: 100%;
}

h1,
h3,
h5 {
  font-weight: 300 !important;
}

h1 {
  font-size: 48px !important;
}

h3 {
  font-size: 36px !important;
  line-height: 1.4 !important;
  letter-spacing: 0.02em;
}

h5 {
  font-size: 24px !important;
  line-height: 1.6 !important;
  letter-spacing: 0.06em !important;
}

p {
  font-weight: 400 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.04em !important;
}

span {
  /* font-weight: 400 !important; */
  /* letter-spacing: 0.06em !important; */
  /* line-height: 1.6 !important; */
}

em {
  font-weight: 300;
  letter-spacing: 0.06em;
  line-height: 1.6;
  margin-bottom: 25px;
}

a {
  text-decoration: none !important;
  letter-spacing: 0.02em !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

@media (max-width: 768px) {
  h1 {
    font-size: 32px !important;
  }

  h3 {
    font-size: 24px !important;
  }
}
