#buy-token {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#buy-token #buy-token-nav {
  width: 100%;
  height: 96px;
}

#buy-token #buy-token-nav .padding {
  padding-left: 50px;
  padding-right: 50px;
}

#buy-token #buy-token-content {
  background-color: #f3f3f3;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding: 0px 50px; */
  padding-top: 49px;
}

.box-column {
  height: auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.box-column .top-text {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 52px;
}

.box-column .top-text a {
  font-size: 12px !important;
  margin-left: 2px;
}

.box-column .bottom-text {
  margin-bottom: 155px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
}

#buy-token-content .transfer-box {
  max-width: 540px;
  width: calc(100% - 30px);
  min-width: 340px;
  padding: 0px 46px;
  padding-top: 41px;
  padding-bottom: 36px;
  height: max-content;
  display: flex;
  margin: auto;
  flex-direction: column;
  background-color: white;
  border: 1px solid #dbdada;
}

#buy-token-content .buy-token-row-box {
  /* justify-content: center; */
}

#buy-token-nav .metamask-info__holder {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#buy-token-footer {
  height: 36px;
  width: 100%;
}

.flex-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.connect-wallet-button {
  margin-left: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 173px;
  border: 1px solid #1f9c42;
  background-color: transparent;
  color: #1f9c42;
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 700;
  transition: background-color 200ms ease;
}

.connect-wallet-button:disabled {
  color: hsl(137, 0%, 37%);
  border: 1px solid hsl(137, 0%, 37%);
  opacity: 0.7;
}

.connect-wallet-button:hover:not(:disabled) {
  background-color: rgba(31, 156, 66, 0.05);
}

.claim-tokens-button {
  background-color: transparent;
  width: max-content;
  border: unset;
  color: rgba(27, 26, 23, 0.4);
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 500;
}

.box-title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #1b1a17;
  margin-bottom: 45px;
}

.bnb-input {
  margin-bottom: 12px;
}

.skey-input {
  margin-bottom: 12px;
}

.double-arrow {
  margin-bottom: 12px;
}

.g2n-input {
  margin-bottom: 50px;
}

.crypto-input {
  border: none;
  background-color: #f6f6f6;
  position: relative;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  z-index: 2;
  margin-bottom: 0px;
  padding: 0px;

  -moz-appearance: textfield;
}

.crypto-input:disabled {
  opacity: 0.6;
}

.crypto-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.crypto-input:focus {
  outline: red;
}

.input-holder {
  height: max-content;
  width: auto;
  position: relative;
  padding-top: 22px;
  padding-left: 33px;
  padding-right: 145px;
  padding-bottom: 47px;
  background-color: #f6f6f6;
  margin-bottom: 12px;
}

.input-holder.BNB .error {
  bottom: calc(0px - 36px);
}

.input-holder.G2N .error {
  bottom: calc(0px - 36px);
}

.input-holder.Skey .error {
  bottom: calc(0px - 36px);
}

.input-holder .error {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 36px;
  background-color: #f03535;
  display: flex;
  align-items: center;
  padding-left: 34px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.watch-how-to-buy {
  text-align: center;
  font-size: 12px !important;
}

div.balance {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #626262;
}

.crypto-title {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: rgba(27, 26, 23, 1);
  line-height: 1 !important;
  margin-top: 5px;
  margin-bottom: 20px;
}

.buy-token-button {
  width: 241px;
  height: 48px;
  background-color: #ffa800;
  border: unset;
  color: #1b1a17;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 32px;
  margin-bottom: 16px;
}

.buy-token-button:disabled {
  background-color: #f6f6f6;
  color: #a6a6a6;
  border: 1px solid #aeaeae;
  /* opacity: 0.8; */
}

.input-absolute-element {
  display: flex;
  flex-direction: row;
  width: max-content;
  justify-content: flex-end;
  position: absolute;
  right: 30px;
  top: 23px;
}

.short-address {
  padding: 10px 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 700;
  background-color: white;
  margin-left: 49px;
}

.bnb-balance {
  margin-left: 49px;
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

.bnb-balance p {
  margin-bottom: 0px;
}

.bnb-balance.spin {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 2px;
}

.bnb-loading {
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 49px;
  min-width: 132px;
  justify-content: flex-end;
}

.skey-loading {
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 49px;
  min-width: 132px;
  justify-content: flex-end;
}

.bnb-loading.spin {
  min-width: 0px;
}

.spinner-img {
  margin-right: 5px;
  margin-left: 5px;
}

.wrong-network {
  background-color: #f03535;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-left: 49px;
}

.modal-backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  left: 50%;
  transform: translateX(-50%);
}

.done-modal-top {
  width: 540px;
  height: max-content;
  background-color: white;
  padding: 41px 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.done-modal-bottom {
  width: 540px;
  height: max-content;
  background-color: #f6f6f6;
  padding: 41px 46px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: auto;
}

.modal-wrapper .done-title {
  align-self: flex-start;
  margin-bottom: 28px;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0.02em !important;
}

.modal-wrapper .done-title.pending {
  margin-bottom: 56px;
}

.modal-wrapper .succes {
  margin-bottom: 29px;
}

.modal-wrapper .done-info {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #1f9c42;
}

.modal-wrapper .pending-spinner {
  margin-bottom: 29px;
  animation: rotate 1.2s linear infinite;
}

.modal-info {
  margin-bottom: 36px;
}

.info-title {
  margin-bottom: 73px;
  align-self: flex-start;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0.02em !important;
  color: rgba(30, 85, 226, 1);
}

.info-description {
  margin-bottom: 54px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-wrapper .done-info.error {
  color: #f03535;
  margin-bottom: 45px;
  max-width: 400px;
  text-align: center;
  overflow-wrap: break-word;
  max-height: 150px;
  overflow: auto;
  width: 100%;
}

.modal-wrapper .pending-info {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #1b1a18;
}

.done-modal-bottom .done-hash {
  overflow-wrap: break-word;
  width: 100%;
  cursor: pointer;
  margin-bottom: 58px;
}

.pending-hash {
  overflow-wrap: break-word;
  width: 100%;
  cursor: pointer;
}

.pending-view {
  margin-bottom: 8px;
  line-height: 1 !important;
  font-size: 12px;
  align-self: flex-start;
}

.pending-hash-title {
  margin-bottom: 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  align-self: flex-start;
}

.done-modal-bottom .done-hash-title {
  margin-bottom: 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
}

.done-modal-bottom .view {
  margin-bottom: 8px;
  line-height: 1 !important;
  font-size: 12px;
}

.done-modal-bottom .close {
  align-self: center;
  width: 281px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20;
  font-weight: 600;
  background-color: transparent;
  border: 2px solid #1b1a17;
  color: #1b1a17;
}

.modal-wrapper .cross {
  position: absolute;
  right: 46px;
  top: 38px;
}

.error-message {
  max-width: 340px;
  text-align: center;
  color: #f03535;
}

.error-icon {
  margin-bottom: 27px;
}

.close-orange {
  align-self: center;
  width: 281px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  background-color: #ffa800;
  border: unset;
  color: #1b1a17;
}

.close-orange:disabled {
  background-color: #e7e7e7;
  color: #a6a6a6;
}

.confirm-modal-top {
  padding-bottom: 23px !important;
  padding: 41px 46px;
  width: 540px;
  height: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.confirm-modal-bottom {
  padding-bottom: 46px !important;
  padding: 23px 46px;
  width: 540px;
  height: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.confirm-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0.02em !important;
  color: #1b1a18;
  align-self: flex-start;
  margin-bottom: 45px;
}

.confirm-input {
  width: 100%;
  /* height: 64px; */
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 17px 30px;
  margin-bottom: 11px;
}

.confirm-token-logo {
  height: max-content;
  width: max-content;
  display: flex;
  align-items: center;
}

.confirm-token-name {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1 !important;
  color: #1b1a17;
  margin-right: 19px;
}

.confirm-token-value {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1 !important;
  color: #1b1a17;
}

.confirm-modal-bottom {
  background-color: #f6f6f6;
}

.confirm-bnb-price {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
  line-height: 1 !important;
  align-self: flex-end;
  font-size: 14px;
  letter-spacing: 0px !important;
}

.confirm-skey-price {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
  line-height: 1 !important;
  align-self: flex-end;
  font-size: 14px;
  letter-spacing: 0px !important;
}

.confirm-title.details {
  margin-bottom: 26px;
}

.confirm-fee {
  margin-bottom: 0px;
  line-height: 1 !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
}

.fee-wrap {
  height: max-content;
  width: max-content;
  display: flex;
  align-items: center;
}

.confirm-fee-value {
  margin-bottom: 0px;
  line-height: 1 !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  width: max-content;
  margin-right: 5px;
}

.token-balance-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 19px;
}

.spinner {
  margin-right: 8px;
}

.token-progress-bar {
  margin: auto;
  max-width: 540px;
  width: calc(100% - 30px);
  min-width: 330px;
  display: flex;
  margin-top: 28px;
  flex-direction: column;
}

.token-progress-bar .top-info {
  width: 100%;
  margin-bottom: 44px;
  display: flex;
  justify-content: space-between;
}

.token-progress-bar .top-info .sold {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(27, 26, 23, 0.8);
}

.token-progress-bar .top-info .left {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: end;
  display: flex;
  align-items: center;
}

.token-progress-bar .bar-outer {
  height: 8px;
  width: 100%;
  background-color: white;
  border: 1px solid #dbdada;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.token-progress-bar .bar-inner {
  height: 10px;
  background-color: #ffa800;
  position: relative;
}

.token-progress-bar .percentage {
  position: absolute;
  top: -27px;
  right: 0px;
  transform: translateX(50%);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
}

.token-progress-bar .percentage .amount {
  margin-bottom: 30px;
  position: relative;
}

.token-progress-bar .percentage::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: -3px;
  clip-path: polygon(100% 50%, 0 50%, 50% 100%);
  background-color: #ffa800;
  height: 13px;
  width: 12px;
}

.token-progress-bar .bottom-info {
  display: flex;
  justify-content: space-between;
}

.buy-box-mobile {
  width: 100%;
  height: max-content;
  padding: 30px 15px;
  background-color: rgb(35, 36, 38);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-box-mobile hr {
  height: 2px;
  width: 100%;
  background-color: #ffa800;
  opacity: 1;
  margin-bottom: 32px !important;
}

.buy-box-mobile p {
  color: white;
  font-size: 16px;
  margin-bottom: 36px;
  text-align: center;
}

.buy-box-mobile button {
  height: 56px;
  width: 200px;
  background-color: transparent;
  border: 2px solid #ffa800;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 32px;
  font-weight: 500;
}

.buy-box-mobile button .metamask {
  margin-right: 8px;
  height: 32px;
  width: 32px;
}

.transfer-box .prices {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.transfer-box .prices p {
  font-size: 14px;
  font-weight: 400;
  color: #1b1a17;
}

.select-way {
  display: inline-flex;
  width: max-content;
  position: relative;
  height: max-content;
}

.select-way .option {
  background-color: gray;
  width: 100%;
  text-align: start;
  padding: 0px 5px;
  color: white;
}

.chewron {
  height: 22px;
  width: 22px;
  margin-left: 12px;
}

.collapsed {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -50px;
  left: 0;
  z-index: 2;
  width: 100%;
}

.hidden {
  display: none;
}

.skey-balance p {
  margin-bottom: 0px;
  margin-left: 49px;
}

p.s1 {
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  font-weight: 400;
  margin-bottom: 0px;
}

p.s2 {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.37px;
  font-weight: 400;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  #buy-token #buy-token-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
  }

  #buy-token-content .buy-token-row-box {
    justify-content: center;
  }

  #buy-token #buy-token-nav .padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .crypto-input {
    font-size: 22px;
  }

  .crypto-input::-moz-focus-inner {
    background-color: green;
  }

  .input-holder {
    padding-left: 15px;
    padding-right: 110px;
    padding-top: 15px;
  }

  .input-absolute-element {
    right: 15px;
    top: 15px;
  }

  .box-title {
    margin-bottom: 25px;
    margin-left: 15px;
  }

  .modal-wrapper {
    width: calc(100% - 30px);
  }

  .confirm-modal-top {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  }

  .confirm-modal-bottom {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px !important;
  }

  .confirm-input {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-wrapper .cross {
    top: 30px;
    right: 25px;
  }

  .done-modal-top {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 27px;
  }

  .done-modal-bottom {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
  }

  .flex-center-between {
    height: 64px;
  }

  .token-balance-wrapper {
    margin-right: 8px;
  }

  .crypto-title {
    font-size: 22px !important;
    margin-bottom: 15px;
  }

  .claim-tokens-button {
    display: none;
  }

  .short-address {
    padding: 8px 12px;
    font-size: 12px;
    margin-left: 17px;
  }

  #buy-token-content .transfer-box {
    padding-bottom: 36px;
  }

  .done-hash {
    margin-bottom: 32px !important;
  }

  .wrong-network {
    padding: 5px 10px;
    font-size: 10px;
    margin-left: 17px;
    line-height: 1.4;
    height: 34px;
  }

  .input-holder .error {
    padding-left: 15px;
  }

  .bnb-balance {
    margin-left: 19px;
  }
  .bnb-balance p {
    font-size: 12px;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
  }

  .bnb-loading {
    margin-left: 19px;
  }

  .info-description {
    margin-bottom: 16px;
  }

  .box-column .bottom-text {
    margin-bottom: 0px;
  }
}

@media (max-width: 500px) {
  #buy-token-content .transfer-box {
    padding-left: 15px;
    padding-right: 15px;
  }
}
