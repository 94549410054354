.info-for-investors-modal {
  display: flex;
  height: max-content;
  width: 100%;
  max-width: 1100px;
}

.info-for-investors-modal .video-part {
  width: 520px;
  height: auto;
  background-color: black;
  position: relative;
  overflow: hidden;
}

.info-for-investors-modal .video-part iframe {
  height: 100%;
}

.info-for-investors-modal .info-part {
  flex: 1;
  min-height: max-content;
  height: 100%;
  background-color: rgb(246, 246, 246);
  padding: 80px;
  position: relative;
}

.info-for-investors-modal .info-part .logo {
  width: 96px;
  height: auto;
  margin-bottom: 24px;
}

.info-for-investors-modal .info-part h4 {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 700;
  max-width: 420px;
}

p.s1 {
  margin-bottom: 24px;
}

@media (max-width: 1200px) {
  .info-for-investors-modal {
    flex-direction: column-reverse;
    top: 0px;
    position: absolute;
    padding: 15px;
  }

  .info-for-investors-modal .info-part {
    padding: 40px 20px;
  }

  .info-for-investors-modal .video-part {
    width: 100%;
    height: auto;
  }

  .info-for-investors-modal .video-part iframe {
    height: 40vh;
  }
}