.section {
  background-color: transparent;
}

#upper-video {
  height: 50vw;
  max-height: 700px;
}

#video {
  height: 55vw;
  max-height: 810px;
  min-height: 300px;
  width: 100%;
  position: relative;
  background-color: rgb(246, 246, 246);
}

#video iframe {
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
}

#marketplace {
  position: relative;
}

#token-details {
  background-color: #1b1a17;
  color: #dbdada;
  font-style: normal;
  letter-spacing: 0.1px;

  padding-top: 60px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.token-detail-top-row {
  display: flex;
  flex-direction: row;
  column-gap: 20%;
  justify-content: flex-start;
}

#buy-token-footer {
  background-color: #000000;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-social-media {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 40px;
}

#footer-logo {
  padding-top: 5px;
  margin-right: 30px;
  padding-bottom: 5px;
}

.mobile-video {
  width: 90%;
  height: 500px;
}

/* lg */
@media (max-width: 991px) {
  .token-detail-top-row {
    column-gap: 15px;
  }
}

/* sm */
@media (max-width: 576px) {
  .footer-social-media {
    margin-left: 10px;
  }

  #footer-logo {
    margin-right: 10px;
  }
}
