.full-width-box {
  width: 100%;
  height: max-content;
  margin-bottom: auto;
  position: relative;
}

.space {
  width: 100%;
  height: 72px;
}

.top-left,
.bottom-left,
.top-right,
.bottom-right {
  position: relative;
}
.top-left::before,
.bottom-left::before,
.top-right::after,
.bottom-right:after {
  content: "";
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: #ffa800;
}

.top-left::before {
  left: -30px;
  top: -10px;
}

.bottom-left::before {
  bottom: -10px;
  left: -30px;
}

.top-right::after {
  top: -10px;
  right: -30px;
}

.bottom-right:after {
  right: -30px;
  bottom: -10px;
}

.higher {
  z-index: 2;
}

.lower {
  z-index: 1;
}

.logo {
  height: auto;
}

.link {
  letter-spacing: 0.06em !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
}

.link:hover {
  filter: brightness(1.2);
}

.media-item {
  display: flex;
  height: max-content;
  width: max-content;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  font-size: 12px !important;
  letter-spacing: 0.06em !important;
  line-height: 1.6;
  font-weight: 300 !important;
  color: #f6f6f6;
}

.media-item:hover {
  color: #ffffff;
}

.media-item img {
  margin-right: 0px;
}

.title {
  line-height: 1.2 !important;
  font-size: 48px !important;
}

.relative {
  position: relative !important;
}

.margin-top-40 {
  margin-top: 40px;
}

.title-left {
  text-align: left;
}

.title-right {
  text-align: right;
}

.row-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.column-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-box {
  height: max-content;
  width: 100%;
  display: flex;
  position: relative;
}

.row-box-right {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.column-box-right {
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex-start {
  align-items: flex-start;
}

.center {
  align-items: center;
}

.flex-end {
  align-items: flex-end;
}

.absolute-center {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.orange-outline {
  color: white;
  width: min-content;
}

.orange {
  background-color: #ffa800;
}

.black {
  color: #1b1a17;
}

.link-button {
  text-transform: uppercase;
  padding: 8px 25px;
  font-weight: 500 !important;
  z-index: 2;
}

.link-button.disabled {
  opacity: 0.2;
  color: #1b1a17;
  border: 2px solid #1b1a17;
  cursor: not-allowed;
}

.link-button.active {
  opacity: 1;
  color: #ffa800;
  border: 2px solid #ffa800;
  cursor: pointer;
}

.image {
  position: absolute;
}

.header-orange-outline {
  margin-bottom: 44px;
}

.logo-link {
  height: max-content;
  width: max-content;
}

.scroll-to-top {
  width: max-content;
  height: auto;
  position: fixed;
  right: 32px;
  bottom: 32px;
  filter: drop-shadow(0px 58px 80px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 37.5926px 46.8519px rgba(0, 0, 0, 0.037963))
    drop-shadow(0px 22.3407px 25.4815px rgba(0, 0, 0, 0.0303704))
    drop-shadow(0px 11.6px 13px rgba(0, 0, 0, 0.025))
    drop-shadow(0px 4.72593px 6.51852px rgba(0, 0, 0, 0.0196296))
    drop-shadow(0px 1.07407px 3.14815px rgba(0, 0, 0, 0.012037));
  z-index: 3;
}

#buy-actions {
  padding-top: 120px;
  background-color: #f3f3f3;
}

#buy-actions-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.action-separator {
  border: 1px solid #dbdada;
  height: 0px;
  width: 100%;
  margin-bottom: 65px;
  align-self: center;
}

.action-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-column {
  font-style: normal;
  font-weight: 600;
  padding-bottom: 60px;
  width: 90%;
  max-width: 400px;
}

.action-column-icon {
  min-height: 45px;
  margin-bottom: 30px;
}

.action-column-title {
  font-size: 14px;
  line-height: 160% !important;
  font-weight: 600 !important;

  /* or 22px */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px !important;

  /* 1B1A18 */
  color: #1b1a17;
}

.action-column-content {
  white-space: pre-line;
  min-height: 70px;
  font-size: 12px;
  line-height: 160%;
  min-height: 100px;

  /* or 19px */
  letter-spacing: 0.2px;

  opacity: 0.8;
}

button.buy-column-button {
  width: 160px;
  height: 36px;
  border: 1px solid #1b1a17;
  color: #1b1a17;
  background-color: #f3f3f3;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 29px;
  transition: color 0.2s;
}

button.buy-column-button:hover {
  background-color: #1b1a17;
  color: #f3f3f3;
  border: 1px solid #f3f3f3;
}

button.buy-column-button:disabled {
  background-color: #f6f6f6;
  color: #a6a6a6;
  border: 1px solid #aeaeae;
}

#token-details-container {
  width: 90%;
}

#token-details-header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;

  color: #dbdada;

  padding-bottom: 20px;
}

.token-detail {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.token-detail-label {
  font-style: normal;
  font-weight: normal;

  font-size: 12px;
  line-height: 19px;

  color: #dbdada;
  opacity: 0.8;
}

.token-detail-content {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px;
  word-wrap: break-word;
  letter-spacing: 0.1px !important;

  color: #dbdada;
}

.copy-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.footer-social-medium {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;

  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.04em !important;
  color: #f6f6f6;
  opacity: 0.5;
  text-transform: capitalize;

  transition: opacity 0.3s ease-in-out;
}

.footer-social-medium:hover {
  opacity: 1;
}

.open-in-metamask-button {
  min-width: 160px;
  height: 36px;
  border: 1px solid #1b1a17;
  color: #1b1a17;
  background-color: #f3f3f3;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 29px;
  transition: color 0.2s;
  margin-bottom: 30px;
}

.open-in-metamask-button:visited,
.open-in-metamask-button:hover {
  color: #1b1a17;
}

.open-in-metamask-button:active {
  color: #f3f3f3;
  background-color: #1b1a17;
}

@media (max-width: 1200px) {
  .platform {
    max-width: 379px;
  }
}

/* lg */
@media (max-width: 991px) {
  .header-orange-outline {
    margin-bottom: 490px !important;
  }
  .row-box {
    justify-content: flex-end;
  }

  .media-item {
    margin-right: 32px;
  }

  .media-item img {
    margin-right: 0px;
  }

  .buy-actions-list {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .action-separator {
    width: 80%;
  }

  #token-details-container {
    max-width: 400px !important;
    width: 90%;
  }
}

/* xs */
@media (max-width: 576px) {
  /* .media-item:not(:last-child) {
    margin-bottom: 20px;
  } */

  .action-separator {
    width: 100%;
  }

  .footer-social-medium-name {
    display: none;
  }
}

@media (max-width: 600px) {
  #buy-actions {
    padding-top: 82px;
  }
}

@media (max-width: 1200px) {
  .action-column {
    padding-left: 15px;
    padding-right: 15px;
  }
}
